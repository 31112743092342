body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "FontBold";
  src: url("./fonts/ClashDisplay-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FontExtralight";
  src: url("./fonts/ClashDisplay-Extralight.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
/* Font 2 */
@font-face {
  font-family: "FontLight";
  src: url("./fonts/ClashDisplay-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
/* Font 3 */
@font-face {
  font-family: "FontMedium";
  src: url("./fonts/ClashDisplay-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FontRegul";
  src: url("./fonts/ClashDisplay-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FontRegula";
  src: url("./fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FontRegul";
  src: url("./fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FontRegular';
  src: url('./fonts/ClashDisplay-Regular.woff2') format('woff2'),
       url('./fonts/ClashDisplay-Regular.woff') format('woff'),
       url('./fonts/ClashDisplay-Bold.otf') format('opentype'),
       url('./fonts/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "FontSemibold";
  src: url("./fonts/ClashDisplay-Semibold.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FontInter";
  src: url("./fonts/Inter_18pt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FontJetbrain";
  src: url("./fonts/JetBrainsMono-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FontJetbrains";
  src: url("./fonts/JetBrainsMono-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "FontJetbrainsReg";
  src: url("./fonts/JetBrainsMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FontInterlight";
  src: url("./fonts/Inter_18pt-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "FontInterthin";
  src: url("./fonts/Inter_18pt-Thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FontProxon";
  src: url("./fonts/PROXON.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}



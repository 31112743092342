html.lenis,
html.lenis body {
  height: auto;
}
html {
  scroll-behavior: smooth;
}

body {
  overflow-x: none;
}

.bike-sequence{
  height: 300vh;
}

@media only screen and (max-width: 451px) and (min-width: 320px) {
  body {
    overflow-x: none;
  }
  .scrollbar-container{
    display: none;
  }
  #root {
    overflow-x: hidden;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.BikePlayVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Icon Animation */

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 2);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

a:focus {
  outline: none;
  background: lightgrey;
}

a:focus:not(:focus-visible) {
  background: transparent;
}

a:focus-visible {
  outline: 2px solid red;
  background: transparent;
}

.link {
  cursor: pointer;
  white-space: nowrap;
}

.link--helike {
  position: relative; /* Ensure the parent container is positioned */
  display: inline-block; /* Ensure proper spacing */
}

.link--helike:hover span {
  animation: glitchText 0.4s linear;
  color: #ffdd33;
}

@keyframes glitchText {
  0% {
    opacity: 1;
    transform: translate3d(-10px, 0, 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  10% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  20% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  35% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  50% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  60% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  70% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  80% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  90% {
    transform: translate3d(-10px, 0, 0) scale3d(-1, -1, 1);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

.link--helike::before {
  height: 2px;
  opacity: 0;
  background-color: #ffdd33;
  width: 100%;
  content: "";
  position: absolute;
  bottom: -5px; /* Adjust this to place the line below the text */
  left: 0;
}

.link--helike:hover::before {
  opacity: 1;
  animation: glitchLine 0.4s steps(2, start) forwards;
}

@keyframes glitchLine {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10% {
    transform: translate3d(10px, 0, 0);
  }
  20% {
    transform: translate3d(0, 4px, 0);
  }
  30% {
    transform: scale3d(0.1, 1.4, 1) translate3d(0, -25px, 0);
    transform-origin: 100% 0%;
  }
  40% {
    transform: scale3d(1, 0.3, 1) translate3d(0, 25px, 0);
  }
  50% {
    transform: scale3d(0.5, 0.3, 1) translate3d(-100px, -80px, 0);
  }
  60% {
    transform: scale3d(1, 1.25, 1) translate3d(10px, -5px, 0);
  }
  70% {
    transform: scale3d(0.5, 0.5, 1) translate3d(0, 20px, 0);
  }
  80% {
    transform: translate3d(-30px, 10px, 0) scale3d(1, 0.4, 1);
    transform-origin: 100% 0%;
  }
  90% {
    transform: scale3d(1, 0.5, 1) translate3d(0, -15px, 0);
    transform-origin: 0% 50%;
  }
  100% {
    opacity: 1;
  }
}

/* Text Animation */
.font-larger .char[data-char="F"]{
  padding-left:30px;
}
.content__title {
  font-size: 6vw;
  line-height: 0.8;
  text-align: center;
  display: grid;
  letter-spacing: 1px;
  font-weight: 400;
  gap: 0rem;
}
.content_ready {
  gap: 1rem;
}
.content__title--left {
  text-align: left;
}
.content__title--right {
  text-align: right;
}
.content__title::before {
  content: "." counter(section);
  font-weight: 400;
  display: block;
  font-size: 0rem;
  text-align: center;
}

.content__title1 {
  font-size: 6vw;
  line-height: 0.5;
  text-align: center;
  display: grid;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 500;
}
.content__title1--left {
  text-align: left;
}
.content__title1--right {
  text-align: right;
}
.content__title1::before {
  content: "." counter(section);
  font-weight: 400;
  display: block;
  font-size: 0rem;
  text-align: center;
}

.content--full {
  font-family: "FontRegular";
}

.font-upper {
  color: white;
  font-size: 95px;
}
.font-lower {
  color: white;
  font-size: 43px;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: relative;
  padding: var(--page-padding);
}
.content1 {
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: relative;
  padding: var(--page-padding);
}
.content p {
  max-width: 660px;
  margin: 1.5rem auto;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 600;
}
.content1 p {
  max-width: 660px;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 600;
}
.font-medium {
  font-size: 6vw;
}
.font-19 {
  font-weight: initial;
  font-family: "FontRegular";
  font-variation-settings: "slnt" 0, "wght" 610;
}
.font-1 {
  font-family: "FontRegular";
  font-weight: 100;
}
.font-7 {
  font-family: "FontRegular";
  font-weight: 400;
}
.font-8 {
  font-family: "FontRegular";
  font-weight: 400;
}
.font-4 {
  font-family: "FontRegular";
  font-weight: 400;
}
.font-align {
  text-align: justify;
}
.content--full {
  padding: 0;
}
.font-larger {
  font-size: 95px;
  color: white;
}
.font-sub {
  font-size: 5vw;
  display: block;
}

.parallax {
  position: relative;
  will-change: transform;
  transition: transform 0.1s ease-out;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  pointer-events: none; /* Make sure particles are behind other elements */
}
/* Scrollbar CSS */
.scrollbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px; /* Adjust as needed */
  background-color: rgba(0, 0, 0, 0.1); /* Light background for contrast */
  z-index: 9999;
}

.scrollbar-progress {
  height: 100%;
  background-color: #ffdd33; /* Color of the progress bar */
  /* Removed transition for more real-time updates */
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

html {
  scroll-behavior: smooth;
}

.home-para {
  color: white;
  margin: 0;
  padding-top: 0px;
  line-height: 4rem;
  font-family: "FontRegular";
}
.home-container {
  background-color: #000000;
  text-align: center;
  /* overflow: hidden; */
  padding-top: 5%;
}
.home-para1 {
  font-size: 43px;
}
.font-abouteffect {
  font-family: "JetBrains Mono", monospace;
}
.home-intro {
  font-size: 21px;
  padding-top: 1%;
  font-family: "JetBrains Mono", monospace;
}
.fast-para {
  font-size: 95px;
}
.ride-para {
  font-size: 95px;
  color: #ffdd33;
}
.film-para {
  color: white;
}
.home-intro {
  color: white;
  font-family: "JetBrains Mono", monospace;
  word-spacing: 5px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.9
  ); /* Slightly darker overlay for better visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above all other content */
}

.popup-content {
  position: relative;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex; /* Flexbox to center iframe */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.close-btn {
  position: absolute;
  top: 20px; /* Adjusted for better visibility */
  right: 20px;
  background: transparent;
  border: none;
  font-size: 2rem; /* Increased size for better visibility */
  cursor: pointer;
  color: #333;
  z-index: 1001; /* Ensure it sits above the iframe */
}

iframe {
  width: 80%; /* Full width */
  height: 80%; /* Full height */
  border: none; /* Remove border */
}

/* Video section container */
.image-div {
  position: relative;
  width: 60%;
  margin: 0 auto;
  padding-top: 10%;
  transition: filter 0.3s ease-in-out; /* Smooth transition */
}

/* Video styling */
.HomeBike-video {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.5s ease;
}

/* Play text that appears on hover */
.play-text-cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;
  pointer-events: none; /* Ensure it doesn't interfere with the click event */
}

/* Other sections blur effect */
.other-section {
  transition: filter 0.3s ease-in-out;
}

/* Ensure video zooms in smoothly */
.image-div:hover .HomeBike-video {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.film-para {
  margin: 0;
  font-family: "JetBrains Mono", monospace;
  padding-left: 5px;
  padding-top: 2px;
}
.icon-div {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 71%;
  bottom: 17%;
}
.about-col {
  position: relative;
}
.about-01 {
  position: absolute;
  left: 12%;
  font-family: "JetBrains Mono", monospace;
  color: #ffdd33;
}
.section-para {
  font-family: "FontRegular";
  font-size: 35px;
}
.section-container {
  background-color: #000000;
  padding-bottom: 8%;
  padding-top: 10%;
}
.section1-flex {
  display: flex;
  justify-content: space-between;
}
.background-container {
  background-image: url("./assets/backgroundImage.png");
  background-repeat: no-repeat;
}
.section-container .col-6 {
  color: white;
  flex: 1;
}

.section2-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-top: 7%;
}
.dot {
  margin-top: 7%;
  display: flex;
  justify-content: center;
}

.section2-container .col-4 {
  flex: 4;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}
.section2-container .col-3 {
  flex: 3;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.section2-container .col-2 {
  flex: 3;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}
.section2-container .col-1 {
  flex: 2;
  padding: 0 10px;
  display: flex;
  justify-content: center;
}

.section2-container img {
  max-width: 100%;
  display: block;
}
.Image1-image {
  width: auto;
  height: 60%;
}
.Image2-image {
  width: auto;
  height: 80%;
}
.section2-para {
  color: white;
  font-family: "JetBrains Mono", monospace;
  word-spacing: 5px;
  padding-top: 0px;
  line-height: 1.5em;
}
.section3-container {
  background-image: url("./assets/side-view-electric-super-bike-mercellenie.webp");
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.para-tech {
  position: absolute;
  top: -60%;
}
.para-num {
  text-align: justify;
  font-family: "JetBrains Mono", monospace;
  color: #ffdd33;
}
.gear-pic {
  width: 50%;
}
.section3-para {
  margin: 0;
  font-size: 80px;
  font-family: "FontRegular";
  letter-spacing: 1px;
  line-height: 4rem;
  display: flex;
}
.section3-shortpara {
  font-family: "FontLight";
  padding-top: 3%;
  font-size: 20px;
}
.section3-container {
  position: relative;
  color: white;
}
.para-position {
  position: absolute;
  bottom: 0;
  left: 6%;
  bottom: 5%;
  transform: translateY(50%); /* Initial position */
  transition: transform 0.5s ease-out;
}
.battery-flex {
  display: flex;
  justify-content: center;
  color: white;
}
.batteryrot-flex {
  display: flex;
  justify-content: center;
}

.battery-heading {
  flex: 4;
}
.battery-heading1 {
  flex: 8;
  position: relative;
}
.battery-container {
  background-image: url("./assets/backgroundImage.png");
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 40%;
  padding-top: 25px;
  position: relative;
  height: 100vh;
}
.battery-num {
  color: #ffdd33;
  font-family: "JetBrains Mono", monospace;
  padding-left: 13%;
  text-align: justify;
}

.battery-para1 {
  position: absolute;
  right: 15%;
  bottom: 15%;
  font-family: "FontRegular";
  color: white;
  font-size: 18px;
}
.battery-para {
  font-size: 50px;
  padding-left: 17%;
  display: flex;
  /* justify-content: flex-end; */
  font-family: "FontRegular";
  color: white;
}
.battery-rotation {
  flex: 6;
  position: relative;
}
.battery-para2 {
  flex: 6;
}
.batteryrot-heading {
  font-size: 50px;
  margin: 0;
  font-family: "FontRegular";
  color: white;
  display: flex;
}
.batteryrot-para {
  padding-top: 30px;
  font-family: "FontRegular";
  color: white;
  font-size: 20px;
}
.battery-rotation1 {
  flex: 2;
}
#moving-battery {
  transition: transform 0.1s ease;
}

.feature-heading {
  margin: 0;
  padding-top: 0px;
  background-color: #000000;
  /* position: relative; */
}
.vision {
  font-family: "JetBrains Mono", monospace;
  color: #ffdd33;
  position: absolute;
  left: 45%;
}
.feature-para {
  color: white;
  margin: 0;
  text-align: center;
  font-size: 80px;
  font-family: "FontRegular";
  line-height: 5rem;
}
.command-heading {
  font-size: 50px;
  font-family: "FontRegular";
}
.command-para {
  font-size: 10px;
  position: absolute;
  bottom: 23%;
  left: 54%;
  border: 1px solid white;
  border-top: none;
  border-bottom: none;
  border-right: none;
  padding-left: 15px;
  font-family: "FontRegular";
}
.command-flex {
  display: flex;
  justify-content: start;
  color: white;
}
.command-container {
  background-color: #000000;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-left: 75px;
  padding-right: 60px;
}

.column1 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background: linear-gradient(to bottom, #0f0f0f 50%, rgba(29, 29, 29, 0) 100%);
}
.column2 {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background: linear-gradient(to bottom, #0f0f0f 50%, rgba(29, 29, 29, 0) 100%);
}

.sub-column {
  flex: 1;
}

.text {
  display: flex;
  justify-content: start;
  color: white;
}

.image {
  display: flex;
  align-items: center;
  justify-content: end;
}

.art {
  max-width: 75%;
  height: auto;
}
.art1 {
  width: 75%;
  height: auto;
}
.para-command {
  color: #e1e1e1;
  font-family: "Inter", sans-serif;
  opacity: 0.5;
}
.inch-para {
  font-size: 25px;
  font-family: "FontRegular";
}
.text-flex {
  padding-left: 30px;
}
.row-layout {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-left: 75px;
  padding-right: 60px;
  padding-top: 25px;
}

.column-one,
.column-two,
.column-three {
  flex: 1;
  background: linear-gradient(to bottom, #0f0f0f 50%, rgba(29, 29, 29, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.content-wrapper {
  color: white;
  position: relative;
}

.header-text {
  font-size: 25px;
  margin-bottom: 10px;
  font-family: "FontRegular";
}

.description-text {
  color: #e1e1e1;
  font-family: "Inter", sans-serif;
  opacity: 0.5;
  margin-bottom: 20px;
}

.image-display {
  width: 40%;
  height: auto;
  margin-top: 20px;
}
.final-para {
  font-size: 20px;
  font-family: "FontRegular";
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Bikefront-image {
  width: 50vw;
  height: auto;
  transition: opacity 2s ease-in-out;
}

.fade-in {
  animation: fadeIn 2s forwards;
}
.bike-container {
  background-color: #000000;
  display: flex;
  justify-content: center;
}

.bike-background {
  background-image: url("./assets/backgroundImage.png");
  background-repeat: no-repeat;
  width: 100%;
  background-position: bottom;
}
.blend-video {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: auto;
  object-fit: cover;
  mix-blend-mode: lighten;
  pointer-events: none;
  transition: opacity 0.5s ease;
}
.bike-mask {
  width: 70%;
  height: auto;
}
.mask {
  background-color: #000000;
}
.extensive-battery {
  display: flex;
  justify-content: center;
  color: white;
  padding-top: 14%;
}
.battery1-pic {
}
.battery2-pic {
  width: 100%;
}
.moving-battery {
  display: flex;
}
.command-arrangement {
  display: flex;
  flex: 5;
  padding-left: 75px;
  position: relative;
}
.dash-para {
  font-family: "JetBrains Mono", monospace;
  color: #ffdd33;
  text-align: justify;
}
.command-dash {
  padding-top: 4%;
  padding-left: 5%;
}

.content-wrapper1 {
  display: flex;
  justify-content: space-between; /* Creates three columns */
  align-items: flex-end; /* Aligns bike-back and wings-rights at the bottom */
  height: 100%; /* Ensures full-height layout */
}

.bike-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.bike-back,
.bike-position,
.wings {
  flex: 1;
  color: white;
}

.bike-back {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content at the bottom */
  padding: 20px;
  color: white;
}

.bike-position {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 2s ease; /* Add a smooth transition */
}

.fade-in-visible {
  opacity: 1;
}
.wings {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align content at the bottom */
  padding: 20px;
}

.wings-rights {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 140px;
}

.icon-flex {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.wings-work {
  color: white;
  margin-bottom: 10px;
  font-family: "JetBrains Mono", monospace;
  opacity: 0.5;
}
.wings-site {
  color: white;
  text-decoration: none;
}
.wings-site:hover {
  color: #ffdd33;
}

.back-top {
  font-size: 16px;
  font-weight: bold;
}

.top-image {
  margin-right: 10px;
  cursor: pointer;
}

.icon1,
.icon2,
.icon3,
.icon4,
.icon5 {
  width: 30px; /* Adjust size as necessary */
  height: 30px;
}
.icon1,
.icon2,
.icon3,
.icon4,
.icon5:hover {
  color: #ffdd33;
}
.policy {
  font-family: "JetBrains Mono", monospace;
}
.copyrights {
  font-family: "JetBrains Mono", monospace;
  color: #ffdd33;
}

/* Media Queries For Mobile */
@media only screen and (max-width: 451px) and (min-width: 320px) {
  .font-larger {
    font-size: 40px;
  }
.bike-sequence{
  height: 25vh;
}
  .policy {
    text-align: center;
  }
  .home-para1 {
    font-size: 22px;
    letter-spacing: 2px;
  }
  .vision-larger {
    font-size: 25px;
    color: white;
    padding-top: 20%;
    text-align: center;
  }
  .gear-setting {
    display: block !important;
  }
  .gear-container .home-container .section1-container .battery-container {
    margin: 3%;
    padding-bottom: 0;
  }
  .home-container {
    padding-top: 30%;
    overflow-x: hidden;
  }
  /* .art {
    width: 50%;
  } */
  .battery-container {
    padding-bottom: 0%;
    height: 80vh !important;
  }
  .image-div{
    width: 80%;
  }
 
  .column-one,
  .column-two,
  .column-three {
    margin: 15px;
  }
  .about-01 {
    position: relative;
    left: 0%;
  }
  /* .art1 {
    width: 50%;
  } */
  .section2-para {
    display: none;
  }
  .moving-battery {
    padding-top: 10%;
  }
  
  .fast-para {
    font-size: 30px;
  }
  .home-para {
    line-height: 2rem;
  }
  .ride-para {
    font-size: 30px;
  }
  .icon-div {
    display: block;
    left: 45%;
    bottom: 40%;
  }
  .film-para {
    display: none;
  }
  .about-01 {
    margin: 0;
  }
  .icon-image {
    height: 10vh;
  }
  .home-intro {
    line-height: 1.5rem;
    font-size: 14px;
  }
  .Image2-image {
    height: 100%;
  }
  .image-display {
    width: 75%;
    margin-top: 0px;
  }
  .Image1-image {
    width: 100%;
  }
  .HomeBike-image {
    width: 70%;
  }
  .section1-flex {
    display: block;
  }
  .Bikefront-image {
    display: none;
  }
  .section-para {
    font-size: 18px;
    text-align: center;
    margin: 0;
    padding-left: 0%;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
  .section2-container {
    display: none;
  }
  .section2-container .col-3 {
    padding-top: 15px;
  }
  .feature-para {
    font-size: 30px;
    line-height: 2.2rem;
    padding-top: 15px;
  }
  .section3-container {
    height: 60vh;
  }
  .gear-power {
    padding-top: 0%;
    background-size: contain;
  }
  .gear-para {
    padding-top: 35%;
    font-size: 12px;
  }
  .gear-setting {
    display: block;
  }
  .battery-num {
    padding-left: 3%;
  }
  .gear1-pic {
    padding-left: 36%;
  }
  .section3-para {
    font-size: 30px;
    line-height: 2rem;
  }
  .section3-shortpara {
    font-size: smaller;
  }
  .battery-flex {
    display: block;
  }
  .battery-para {
    display: none;
  }
  .batteryrot-para {
    letter-spacing: 1px;
    font-size: smaller;
  }
  .battery-heading {
    position: none;
  }
  .flex-battery {
    display: flex;
    flex-direction: column-reverse;
  }
  .battery-para1 {
    display: none;
  }
  .para-position {
    left: 3%;
    transform: none !important;
  }
  .extensive-battery {
    display: block;
    padding-top: 50%;
  }
  .battery-para2 {
    padding-top: 20px;
  }
  .batteryrot-heading {
    font-size: 30px;
    padding-left: 15px;
    padding-top: 15px;
  }
  .command-arrangement {
    display: initial;
  }
  .command-heading {
    font-size: 35px;
    margin: 0;
  }
  .command-para {
    position: initial;
  }
  .row {
    display: initial;
    padding-left: 0px;
    padding-right: 0px;
  }
  .column1 {
    display: block;
    margin: 15px;
  }
  .image {
    display: flex;
    justify-content: center;
  }
  .column2 {
    display: block;
  }
  .row-layout {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
  }
  .content-wrapper1 {
    display: block;
  }
  .wings-rights {
    padding-left: 0px;
  }
  .command-arrangement {
    padding-left: 15px;
  }
  .vision {
    left: 30%;
  }
  .battery1-pic {
    width: 100%;
  }
  .batteryrot-flex {
    justify-content: flex-start;
    padding-left: 5%;
  }
  .header-text {
    font-size: 18px;
  }
  .description-text {
    font-size: 15px;
    margin-top: 5px;
  }
  .inch-para {
    font-size: 18px;
    margin-bottom: 0;
  }
  .para-command {
    font-size: 15px;
    margin-top: 5px;
  }
  .column2 {
    margin: 15px;
  }
  .batteryrot-heading {
    display: block;
  }
  .para-tech {
    /* top: -97%; */
    position: relative;
  }
  .gear-watt,
  .gear-torque .gear-torque {
    font-size: 25px !important;
  }
  .font-watt {
    font-size: 30px !important;
  }
  .kilowatt-space {
    font-size: 30px !important;
  }
  .gear-torque {
    right: 5%;
  }
  .content1 p {
    line-height: 0;
    font-size: medium;
  }
  .font-upper {
    font-size: 40px;
    padding-top: 5px;
  }
  .font-lower {
    font-size: 15px;
  }
  .feature-heading .pin-spacer {
    padding: 0px !important;
    height: 0px !important;
  }
  .BikePlayVideo {
    object-fit: contain;
  }
  .top {
    display: none;
  }
  .icon-flex {
    gap: 20px;
  }
  .icon1,
  .icon2,
  .icon3,
  .icon4,
  .icon5 {
    width: 20px;
  }
}
@media only screen and (max-width: 769px) and (min-width: 451px) {
  .battery2-pic {
    width: 70%;
  }
  /* .art {
    width: 50%;
  }
  .art1 {
    width: 50%;
  } */
  .icon-div {
    display: block;
    left: 42%;
    bottom: 32%;
  }
  .film-para {
    display: none;
  }
  .about-01 {
    margin: 0;
    left: 40%;
  }
  .icon-image {
    height: 15vh;
  }
  .home-intro {
    line-height: 1.5rem;
    font-size: 12px;
  }
  .HomeBike-image {
    width: 70%;
  }
  .home-container {
    padding-top: 30%;
  }
  .font-larger {
    font-size: 40px;
  }
  .section1-flex {
    display: block;
  }
  .section-para {
    font-size: 16px;
    padding-top: 60px;
    text-align: center;
    margin: 0;
    padding-left: 0%;
  }
  .section2-container {
    display: block;
  }
  .gear-setting {
    display: block;
  }
  .gear1-pic {
    padding-left: 36%;
  }
  .section3-para {
    font-size: 30px;
    line-height: 2rem;
  }
  .battery-flex {
    display: block;
  }
  .battery-para {
    display: none;
  }
  .battery-heading {
    position: none;
  }
  .flex-battery {
    display: block;
    position: none;
  }
  .battery-para1 {
    display: none;
  }
  .battery-para2 {
    padding-top: 15%;
  }
  .battery-container {
    padding-bottom: 0%;
  }
  .extensive-battery {
    display: block;
  }
  .batteryrot-heading {
    font-size: 30px;
    padding-left: 50px;
    padding-top: 20px;
  }
  .command-arrangement {
    display: initial;
    padding-left: 35px;
  }
  .command-heading {
    font-size: 35px;
  }
  .command-para {
    position: initial;
  }
  .row {
    display: initial;
    padding-left: 0px;
    padding-right: 0px;
  }
  .column1 {
    display: block;
  }
  .image {
    /* display: block; */
  }
  .column2 {
    display: block;
    padding-top: 50px;
  }
  .row-layout {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
  }
  .content-wrapper1 {
    display: block;
  }
  .battery-num {
    padding-left: 11%;
  }
  .moving-battery {
    justify-content: center;
    text-align: left;
  }
  .wings-rights {
    padding-left: 0px;
  }
  .vision {
    left: 38%;
  }
  .battery1-pic {
    width: 70%;
  }
  .batteryrot-flex {
    justify-content: center;
  }
  .header-text {
    font-size: 20px;
  }
  .description-text {
    font-size: 12px;
  }
  .inch-para {
    font-size: 20px;
  }
  .para-command {
    font-size: 15px;
  }
  .batteryrot-heading {
    display: block;
  }
  .para-tech {
    top: -150%;
  }
  .gear-watt {
    font-size: 24px;
  }
  .font-upper {
    font-size: 45px;
  }
  .font-lower {
    font-size: 20px;
  }
  .gear-torque {
    font-size: 24px;
  }
  .font-watt {
    font-size: 20px;
    display: flex;
  }
  .kilowatt-space {
    font-size: 30px;
  }
  .mask-images {
    margin-top: 15%;
  }
  .feature-para {
    font-size: 35px;
    line-height: 2rem;
    padding-top: 15px;
  }
  .home-para1 {
    font-size: 15px;
    letter-spacing: 2px;
  }
  .fast-para {
    font-size: 30px;
  }
  .home-para {
    line-height: 2rem;
  }
  .ride-para {
    font-size: 30px;
  }
  .section2-container .col-3 {
    padding-top: 60px;
  }
  .bike-mask {
    width: 80%;
  }
  .feature-heading .pin-spacer {
    padding: 0px;
    height: 0px;
  }
}
@media only screen and (max-width: 1030px) and (min-width: 770px) {
  .icon-div {
    left: 64%;
  }
  .battery1-pic {
    width: 100%;
  }
  .extensive-battery {
    padding-top: 80px;
  }
  .section-para {
    padding-left: 0;
    font-size: 25px;
  }
  .Image2-image {
    height: 40vh;
  }
  .Image1-image {
    height: 40vh;
  }
  .Mercelline-image {
    height: 40vh;
  }
  .Gloves-image {
    height: 40vh;
  }
  .para-tech {
    top: -45%;
  }
  .battery-para {
    padding-left: 90px;
    font-size: 34px;
  }
  .batteryrot-heading {
    font-size: 30px;
  }
  .battery-para1 {
    display: none;
  }
  .battery-num {
    padding-left: 11%;
  }
  .feature-para {
    font-size: 40px;
    line-height: 4rem;
  }
  .command-para {
    left: 75%;
  }
  .command-arrangement {
    padding-left: 42px;
  }
  .inch-para {
    font-size: 20px;
  }
  .para-command {
    font-size: 15px;
  }
  .header-text {
    font-size: 20px;
  }
  .description-text {
    font-size: 15px;
  }
  .section3-para {
    font-size: 50px;
    line-height: 4rem;
  }
  .wings-rights {
    padding-left: 0%;
  }
  .battery-container {
    padding-bottom: 0% !important;
  }
}
@media only screen and (max-width: 920px) and (min-width: 778px) {
  .command-para {
    left: 87%;
  }
  .para-tech {
    top: -45%;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 1031px) {
  .command-para {
    left: 72%;
  }
  .icon-div {
    left: 68%;
  }
  .para-tech {
    top: -45%;
  }
  .battery-para1 {
    position: absolute;
  }
  .extensive-battery {
    padding-top: 80px;
  }
  .battery-para {
    padding-left: 75px;
    font-size: 40px;
  }
  .battery-para2{
    padding-top: 10%
  }
}
@media only screen and (max-width: 1374px) and (min-width: 1301px) {
  .command-para {
    left: 58%;
  }
  .para-tech {
    top: -45%;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1500px) {
  .extensive-battery {
    padding-top: 25%;
  }
  .battery-para2 {
   
  }
}

@media only screen and (max-width: 2561px) and (min-width: 1920px) {
  .battery-para {
    font-size: 80px;
  }
  .battery-para1 {
    font-size: 20px;
  }
  .battery-num {
    padding-left: 20%;
  }
  .batteryrot-heading {
    font-size: 80px;
  }
  .batteryrot-para {
    font-size: 25px;
  }
  .dash-para {
    font-size: 22px;
  }
  .para-tech {
    position: absolute;
    top: -60%;
  }
  .battery-container {
    padding-bottom: 0;
    height: 120vh !important;
  }
  .battery1-pic {
    width: 50%;
  }
  .section2-container {
    height: 60vh;
  }
  .about-01 {
    font-size: 22px;
    left: 20%;
  }
  .section-para {
    font-size: 45px;
    line-height: 3.1rem;
  }
  .section2-para {
    font-size: 25px;
  }
  .gear-para {
    font-size: 30px;
  }
  .para-num {
    font-size: 22px;
  }
  .section3-para {
    font-size: 90px;
    line-height: 5rem;
  }
  .section3-shortpara {
    font-size: 25px;
  }
  .command-para {
    font-size: 20px;
  }
  .command-heading {
    font-size: 70px;
  }
  .inch-para {
    font-size: 42px;
  }
  .para-command {
    font-size: 25px;
  }
  .header-text {
    font-size: 42px;
  }
  .description-text {
    font-size: 25px;
  }

  .art1 {
    height: 30vh;
  }
  .image-display {
    height: 20vh;
  }
  .final-para {
    font-size: 30px;
  }
  .back-top {
    font-size: 25px;
  }
  .policy {
    font-size: 10px;
  }
  .wings-work {
    font-size: 18px;
  }
  .command-container {
    padding: 5%;
  }
  .command-dash {
    padding-left: 3%;
  }
  .wings-rights {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 3100px) and (min-width: 2570px) {
.battery-para2{
  padding-top: 20%;
}
}

.bikeSequence-container {
  background-color: #000000;
  position: relative;
}

.bikeSequence-pic {
  width: 100%;
  transition: transform 0.5s ease;
  padding-top: 0%;
  z-index: 2;
}

.bikeSequence {
  position: relative;
}

.bikeSequence-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: none;
  mix-blend-mode: lighten;
}
.overlay-image {
  position: absolute;
  top: 24%;
  left: 4%;
  width: 17%;
  height: 3%;
  pointer-events: none;
}
.smoke-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: lighten;
  z-index: 2;
  pointer-events: none;
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  .bikeSequence-container {
    padding-top: 10%;
    overflow-x: hidden;
  }
  .bikeSequence-pic{
    transform: scale(1.5);
    width: 95%;
  }
}

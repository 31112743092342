.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 1000;
}

.iframe-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Keep it behind the content */
}

.loading-text {
  font-size: 8rem;
  color: #bebebe;
  font-family: "FontProxon";
  transition: opacity 0.8s ease-in-out;
  opacity: 0.7;
  margin: 0;
  position: relative;
  z-index: 1; /* Keep the text above the iframe */
  bottom: 12%;
}

.loading-image {
  width: 45%;
  z-index: 1; 
  mix-blend-mode: screen;
}

/* Fade in animation */
.fade-in {
  opacity: 1;
}

/* Fade out animation */
.fade-out {
  opacity: 0;
}

@media only screen and (max-width: 451px) and (min-width: 320px) {
  .loading-text {
    font-size: 2rem;
    bottom: 5%;
  }
  .loading-image {
    width: 80%;
  }
}

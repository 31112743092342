/* cursor.css */
.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;
  pointer-events: none;
}

.cursor {
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: 2px solid #ffdd33;
  width: 40px;
  height: 40px;
  opacity: 0.4;
  transition: transform 600ms ease-out;
}

.cursor.pointer {
  transform: translate(-50%, -50%) scale(0.5);
}

.cursor.grab {
  transform: translate(-50%, -50%) scale(1.2);
}

/* Hide the cursor on devices that don't support hover */
@media (hover: none) {
  .container {
    display: none;
  }
}
